.body {
	height: 100vh;
	margin: 0;
	width: 100%;
	
	background-color: #A0A3A8;
}

.ro { color: red; }
.ve { color: green; }
.am { color: yellow; }
.ne { color: black; }
.az { color: blue; }

.b { font-weight: bold; }

.lg { font-size: large; }
.xxl { font-size: xx-large; }

.main{	
	padding-top: 5px;
}

table {
	border: none;
	width:100%
}

td.f {
	width:100%;
	height:300px;
	border-radius: 10px;
	border: 0px;
 }

 td { 
 	text-align: center;
  }

.cap { text-transform: capitalize; }
.cen { text-align: center; }

.loginForm {
	padding-top: 10px;
	background: rgba(0,0,0,0.1);
	color: white;
	font-family: 'LatoRegular';
	
	border-radius: 10px;
	border-style: solid;
	border-color: #131314;
	padding-bottom: 10px;
	width: 100%;
}

.cc { width: 30px; }
.cm { width: 40px; }
.cg { width: 110px; }
.cx { width:*; }

img {
	margin: 5px;
	max-width:260px;
	max-height:300px;
}

